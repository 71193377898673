<template>
	<table>
		<tr :id="`box-id-${treeData.unique_id}`">
			<td :colspan="treeData.children ? treeData.children.length * 2 : 1" :class="{ parentLevel: treeData.children, extend: treeData.children && treeData.children.length > 0 && treeData.extend, 'condition-margin': treeData.condition }">
				<div class="node" :class="{'node-start': treeData.type == 'start'}">
					<!-- PARENT NODE -->
					<template v-if="treeData.type == 'start' && processTriggers.length > 0">
						<div class="box-trigger" v-for="trigger in processTriggers" data-no-dragscroll>
							<div class="content-box">
								<div class="header">{{ $t('general.trigger') }}</div>
								<div class="type">{{ trigger.type == 'weekly' || trigger.type == 'yearly' ? $tc('trigger.text.' + trigger.type, trigger.value.split(",").length ) : $t('trigger.text.' + trigger.type) }}</div>

								<template v-if="['date'].includes(trigger.type)">
									<template v-if="trigger.custom_value && trigger.custom_value.length">
										<div class="value weeks">
											{{
												trigger.custom_value
													.map((a) => {
														return $t(`trigger.value.measurement_dynamically.${a}`);
													})
													.join(", ")
													.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`)
											}}
										</div>
										<div class="value weeks">
											{{ $t(`trigger.value.date_weekly`, { value: trigger.value.split(",")
											.map((el) => {
												return $t(`calendar.long_days.${el}`);
											})
											.join(", ") })
											.replace(/, ([^,]*)$/, ` ${$t("general.and")} $1`) }}
										</div>
									</template>
									<div class="value" v-else>{{ $t(`trigger.value.date_monthly`, { value: trigger.value }) }}</div>
								</template>
								<div class="value" v-else-if="['procedure', 'process'].includes(trigger.type)">{{ trigger.entity_name }}</div>
								<div class="value" v-else-if="['weekly'].includes(trigger.type)">{{ trigger.value.split(",").map(el => { return $t(`calendar.long_days.${el}`) }).join(', ') }}</div>
								<div class="value" v-else>{{ trigger.value }}</div>
							</div>
						</div>
					</template>
					<div class="box-start step-organigrama" v-else-if="treeData.type == 'start'" data-no-dragscroll>
						{{ $t('general.start') }}
					</div>

					<div class="dropdown menu-animated add-steps-menu box-blank-add step-organigrama" v-if="treeData.type == 'blank' && processData.rights.entity_crud && Object.keys(modeLoop).length === 0" data-no-dragscroll>
						<div class="open-menu" :id="'dropdownProcess'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<icon-plus />
						</div>
						<div class="dropdown-menu" :aria-labelledby="'dropdownProcess'+treeData.unique_id">
							<div class="menu-items">
								<div class="btn-animated edit-action" :title="$t('process-chart.process')" @click="showModalStep('add_step', 'process', treeData, fullJson)">
									<icon-chart />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.procedure')" @click="showModalStep('add_step', 'procedure', treeData, fullJson)">
									<icon-procedures />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.simple_text')" @click="showModalStep('add_step', 'text', treeData, fullJson)">
									<icon-text />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.decision')" @click="showModalStep('add_step', 'decision', treeData, fullJson)">
									<icon-decision />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.loop_text_step')" @click="$root.$emit('enter_mode_select_loop', treeData)">
									<icon-loop />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.end')" @click="$root.$emit('add_end_step', treeData)">
									END
								</div>
							</div>
						</div>
					</div>

					<div class="box-tbf department-box step-organigrama" v-bind:class="[treeData.color ? `${treeData.color}` : '']" v-if="treeData.type == 'procedure'" data-no-dragscroll >
						<div class="transparent-div" v-if="Object.keys(modeLoop).length" @click.stop="showModalStep('add_step', 'loop', {...modeLoop, temporarySelectedParentLoop: 
							{ unique_id: treeData.unique_id, name: currentItem ? currentItem.name : (deletedItem ? deletedItem.name : '') }
						}, fullJson)"></div>
						<div class="clickable-box" @click="(processData.rights.entity_crud || currentItem.rights.employee_view) ? showModal('sidebar_chart', false, currentItem.slug) : ''"></div>
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ `${treeData.unique_id}. ${$t('general.procedure')}` }}
								</div>
								<div class="box-actions">
									<div class="icon-is-parent-loop" v-if="treeData.is_parent && treeData.is_parent.length">
										<icon-end-loop />
									</div>
									<div class="button-read-more" :class="{'active': readMoreText}" @click="readMoreText = !readMoreText">
										<icon-text-height />
									</div>
									<div class="dropdown menu-animated simple-box" v-if="(processData.rights.entity_crud || (currentItem ? currentItem.rights.employee_view : false )) && Object.keys(modeLoop).length === 0">
										<div class="open-menu" :id="'dropdownProcedure'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcedure'+treeData.unique_id">
											<div class="menu-items">
												<div v-if="currentItem && processData.rights.entity_crud" class="btn-animated add-action" @click="showModalStep('add_step', '', treeData, fullJson)">
													<icon-plus />
												</div>
												<div v-if="currentItem && (processData.rights.entity_crud || currentItem.rights.employee_view)" class="btn-animated edit-action" @click="showModal('sidebar_chart', false, currentItem.slug)">
													<icon-show />
												</div>
												<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
													<icon-trash />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="currentItem" class="title" :class="{link: true, 'read-more': readMoreText}">
								<span>{{ processData.rights.entity_crud || currentItem.rights.employee_view ? currentItem.name : $t('procedures.private') }}</span>
							</div>
							<div v-else-if="deletedItem" class="title deleted">
								<span>{{ deletedItem ? deletedItem.name : '' }}</span>
							</div>
						</div>
					</div>

					<!-- CHILD LIGHT GREY -->
					<div class="box-tbf compay-box step-organigrama" v-bind:class="[treeData.color ? `${treeData.color}` : '']" v-if="treeData.type == 'process'" data-no-dragscroll>
						<div class="transparent-div" v-if="Object.keys(modeLoop).length" @click.stop="showModalStep('add_step', 'loop', {...modeLoop, temporarySelectedParentLoop: 
							{ unique_id: treeData.unique_id, name: currentItem ? currentItem.name : (deletedItem ? deletedItem.name : '') }
						}, fullJson)"></div>
						<router-link class="clickable-box" :to="{ name: 'process-show', params: {slug: currentItem.slug}, query: { parent: processData.slug } }" v-if="processData.rights.entity_crud || currentItem.rights.employee_view">
						</router-link>
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ `${treeData.unique_id}. ${$t('general.process')}` }}
								</div>
								<div class="box-actions">
									<div class="icon-is-parent-loop" v-if="treeData.is_parent && treeData.is_parent.length">
										<icon-end-loop />
									</div>
									<div class="button-read-more" :class="{'active': readMoreText}" @click="readMoreText = !readMoreText">
										<icon-text-height />
									</div>
									<div class="dropdown menu-animated simple-box" v-if="(processData.rights.entity_crud || (currentItem ? currentItem.rights.employee_view : false )) && Object.keys(modeLoop).length === 0">
										<div class="open-menu" :id="'dropdownProcess'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcess'+treeData.unique_id">
											<div class="menu-items">
												<div v-if="currentItem && processData.rights.entity_crud" class="btn-animated add-action" @click="showModalStep('add_step', '', treeData, fullJson)">
													<icon-plus />
												</div>
												<div v-if="currentItem && (currentItem.rights.employee_view || processData.rights.entity_crud)" class="btn-animated edit-action" @click="viewProcess(currentItem)">
													<icon-show />
												</div>
												<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
													<icon-trash />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="currentItem" class="title" :class="{'read-more': readMoreText}">
								<span v-if="processData.rights.entity_crud || currentItem.rights.employee_view">{{ currentItem.name }}</span>
								<span v-else>{{ $t('processes.private') }}</span>
							</div>
							<div v-else-if="deletedItem" class="title deleted">
								<span>{{ deletedItem ? deletedItem.name : '' }}</span>
							</div>
						</div>
					</div>

					<div class="box-tbf-decision step-organigrama" v-bind:class="[treeData.color ? `${treeData.color}` : '']" v-if="treeData.type == 'decision'" data-no-dragscroll>
						<div class="transparent-div" v-if="Object.keys(modeLoop).length" @click.stop="showModalStep('add_step', 'loop', {...modeLoop, temporarySelectedParentLoop: 
							{ unique_id: treeData.unique_id, name: treeData.name }
						}, fullJson)"></div>
						<div class="dropdown menu-animated">
							<div :id="'dropdownDecision'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="clickable-decision">
								<div class="content-box">
									<v-popover class="header-box" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="title">{{ `${treeData.unique_id}. ${treeData.name}` }}</div>
										<template slot="popover">
											<div class="simple-text">{{ treeData.name }}</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="dropdown-menu" :aria-labelledby="'dropdownDecision'+treeData.unique_id" v-if="processData.rights.entity_crud">
								<div class="menu-items">
									<div class="btn-animated edit-action" @click="showModalStep('edit_step', '', treeData, fullJson)">
										<icon-edit />
									</div>
									<div class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
										<icon-trash />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="box-tbf simple-text-box step-organigrama" v-bind:class="[treeData.color ? `${treeData.color}` : '']" v-if="treeData.type == 'simple_text'" data-no-dragscroll>
						<div class="transparent-div" v-if="Object.keys(modeLoop).length" @click.stop="showModalStep('add_step', 'loop', {...modeLoop, temporarySelectedParentLoop: 
							{ unique_id: treeData.unique_id, name: treeData.name }
						}, fullJson)"></div>
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ `${treeData.unique_id}. ${$t('process-chart.simple_text')}` }}
								</div>
								<div class="box-actions">
									<div class="icon-is-parent-loop" v-if="treeData.is_parent && treeData.is_parent.length">
										<icon-end-loop />
									</div>
									<div class="button-read-more" :class="{'active': readMoreText}" @click="readMoreText = !readMoreText">
										<icon-text-height />
									</div>
									<div class="dropdown menu-animated simple-box" v-if="processData.rights.entity_crud && Object.keys(modeLoop).length === 0">
										<div class="open-menu" :id="'dropdownProcedure'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcedure'+treeData.unique_id">
											<div class="menu-items">
												<div v-if="processData.rights.entity_crud" class="btn-animated add-action" @click="showModalStep('add_step', '', treeData, fullJson)">
													<icon-plus />
												</div>
												<div v-if="processData.rights.entity_crud" class="btn-animated edit-action" @click="showModalStep('edit_step', '', treeData, fullJson)">
													<icon-edit />
												</div>
												<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
													<icon-trash />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title" :class="{'read-more': readMoreText}">
								<span>{{ treeData.name }}</span>
							</div>
						</div>
					</div>

					<div class="box-tbf loop-step-box step-organigrama" v-bind:class="[treeData.color ? `${treeData.color}` : '']" v-if="treeData.type == 'loop'" data-no-dragscroll @mouseover="changeOpacity(treeData.unique_id, treeData.selectedParentLoop.unique_id)" @mouseleave="changeOpacity2(treeData.unique_id, treeData.selectedParentLoop.unique_id)">
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ `${treeData.unique_id}. ${$t('process-chart.loop_text_step')}` }}
								</div>
								<div class="box-actions">
									<div class="loop_link">
										<icon-loop />
										<div class="text">{{ treeData.selectedParentLoop.unique_id }}</div>
									</div>
									<div class="dropdown menu-animated simple-box" v-if="processData.rights.entity_crud && Object.keys(modeLoop).length === 0">
										<div class="open-menu" :id="'dropdownProcedure'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcedure'+treeData.unique_id">
											<div class="menu-items">
												<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
													<icon-trash />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title" :class="{'read-more': readMoreText}" @click="scrollToParent(treeData.selectedParentLoop.unique_id)">
								<span>{{ treeData.name }}</span>
							</div>
						</div>
					</div>

					<div class="box-end step-organigrama" v-if="treeData.type == 'end'" data-no-dragscroll>
						<div class="dropdown menu-animated">
							<div :id="`dropdownEnd-${treeData.unique_id}-${temporaryNr}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<div class="content-box">
									<div class="header-box">
										<div class="title">{{ $t('general.end') }}</div>
									</div>
								</div>
							</div>
							<div class="dropdown-menu" :aria-labelledby="`dropdownEnd-${treeData.unique_id}-${temporaryNr}`">
								<div class="menu-items">
									<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete(treeData, 'step')">
										<icon-trash />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="extend-handle-tbf" :class="{open: !parentOpen}" @click="toggleExtend(treeData)" v-if="treeData.children && treeData.children.length > 0"></div>

				<div class="add-next-child" v-else-if="!treeData.children && Object.keys(modeLoop).length === 0 && ['process', 'procedure', 'decision', 'simple_text'].includes(treeData.type) && processData.rights.entity_crud">
					<div class="dropdown menu-animated add-steps-menu">
						<div class="open-menu" :id="'dropdownProcess'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<icon-plus />
						</div>
						<div class="dropdown-menu" :aria-labelledby="'dropdownProcess'+treeData.unique_id">
							<div class="menu-items">
								<div class="btn-animated edit-action" :title="$t('process-chart.process')" @click="showModalStep('add_step', 'process', treeData, fullJson)">
									<icon-chart />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.procedure')" @click="showModalStep('add_step', 'procedure', treeData, fullJson)">
									<icon-procedures />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.simple_text')" @click="showModalStep('add_step', 'text', treeData, fullJson)">
									<icon-text />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.decision')" @click="showModalStep('add_step', 'decision', treeData, fullJson)">
									<icon-decision />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.loop_text_step')" @click="$root.$emit('enter_mode_select_loop', treeData)">
									<icon-loop />
								</div>
								<div class="btn-animated edit-action" :title="$t('process-chart.end')" @click="$root.$emit('add_end_step', treeData)">
									END
								</div>
							</div>
						</div>
					</div>
				</div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend" class="children-row-wrapper">
			<td v-for="(children, index) in treeData.children" :key="index" colspan="2" class="childLevel" v-bind:class="{'condition-child': treeData.type == 'decision', 'decision-on-condition': children.type == 'decision'}">
				<div class="decision-text" v-if="treeData.type == 'decision'">
					<div class="dropdown menu-animated">
						<v-popover class="decision-popover" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" :id="'dropdownTextDecision'+treeData.unique_id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div class="text">
								{{ children.condition }}
							</div>
							<template slot="popover">
								<div class="simple-text">{{children.condition}}</div>
							</template>
						</v-popover>
						<div class="dropdown-menu" :aria-labelledby="'dropdownTextDecision'+treeData.unique_id">
							<div class="menu-items">
								<div v-if="processData.rights.entity_crud" class="btn-animated edit-action" @click="showModalStep('edit_step', 'decision', {...children, isCondition: true}, fullJson)">
									<icon-edit />
								</div>
								<div v-if="processData.rights.entity_crud" class="btn-animated delete-action" @click="openModalDelete({...children, isCondition: true}, 'condition')">
									<icon-trash />
								</div>
							</div>
						</div>
					</div>
				</div>

				<ProcessChart 
				:json="children" 
				:fullJson="fullJson" 
				:zoomPage="zoomPage" 
				:processes="processes" 
				:procedures="procedures" 
				:processData="processData" 
				:processTriggers="processTriggers" 
				:modeLoop="modeLoop"
				:temporaryNr="temporaryNr" />
			</td>
		</tr>
	</table>
</template>

<script>
	import EditDotsIcon from "../../Icons/EditDots";
	import IconPlus from "../../Icons/Plus";
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconShow from '../../Icons/Show'
	import IconLoop from '../../Icons/Loop'
	import IconEndLoop from '../../Icons/EndLoop'
	import IconTextHeight from '../../Icons/TextHeight'
	import IconText from '../../Icons/Text'
	import IconProcedures from '../../Icons/ProceduresLight'
	import IconChart from '../../Icons/Chart'
	import IconDecision from '../../Icons/Decision'

export default {
	data() {
		return {
			treeData: {},
			parentOpen: false,
			currentItem: {},
			deletedItem: {},
			readMoreText: false
		};
	},
  	props: {
  		fullJson: Object,
  		json: Object,
  		zoomPage: Number,
  		procedures: Array,
  		processTriggers: Array,
  		processes: Array,
  		processData: Object,
  		modeLoop: Object,
  		temporaryNr: Number
  	},
  	name: "ProcessChart",
	components: {
		EditDotsIcon,
		IconPlus,
		IconEdit,
		IconTrash,
		IconShow,
		IconLoop,
		IconEndLoop,
		IconTextHeight,
		IconText,
		IconProcedures,
		IconChart,
		IconDecision
	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

					if(jsonExtend.find(el => el.unique_id == jsonData.unique_id)){
						jsonData.extend = jsonExtend.find(el => el.unique_id == jsonData.unique_id).extend
					}else{
						jsonData.extend = true
						jsonExtend.push({ id: jsonData.unique_id, extend: jsonData.extend })
					}
					localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))
					return jsonData;
				};
				if(Props) {
					if(typeof Props === 'object'){
						this.treeData = extendKey(Props);
					}
				}

				if(this.treeData.type == 'procedure'){
					this.currentItem = this.procedures.find(el => el.id == this.treeData.id)
				}else if (this.treeData.type == 'process'){
					this.currentItem = this.processes.find(el => el.id == this.treeData.id)
				}
			},
			immediate: true,
		},
	},
	mounted(){
		this.$root.$on('toggleExtendAll', (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
			this.$forceUpdate()

			var noCountExntend = 0
			localStorage.setItem('numberCount', (noCountExntend ? noCountExntend = parseInt(noCountExntend) + 1 : 1))
		})

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

			jsonData.extend = extendValue
			if(jsonExtend.find(el => el.unique_id == jsonData.unique_id)){
				jsonExtend.find(el => el.unique_id == jsonData.unique_id).extend = jsonData.extend
			}else{
				jsonExtend.push({ id: jsonData.unique_id, extend: jsonData.extend })
			}
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			return jsonData;
		};

		var noCount = parseInt(localStorage.getItem('numberCount')) + 1
		localStorage.setItem('numberCount', noCount)

		if(noCount == localStorage.getItem('numberCountExtend')){
			this.centerOrg()
		}
		let deleted_procedures = {}
		let deleted_processes = {}
		if(this.treeData.type == 'procedure'){
			this.currentItem = this.procedures.find(el => el.id == this.treeData.id)
			if(!this.currentItem) {
				deleted_procedures.deleted_procedures_ids = this.procedures.length > 0 ? this.procedures.map(el => {
					if(el.id != this.treeData.id) {
						return this.treeData.id
					}
				}) : [this.treeData.id]
				this.showDeletedItems(deleted_procedures, 'deleted_procedures')
			}
		}else if (this.treeData.type == 'process'){
			this.currentItem = this.processes.find(el => el.id == this.treeData.id)
			if(!this.currentItem) {
				deleted_processes.deleted_processes_ids = this.processes.length > 0 ? this.processes.map(el => {
					if(el.id != this.treeData.id) {
						return this.treeData.id
					}
				}) : [this.treeData.id]
				this.showDeletedItems(deleted_processes, 'deleted_processes')
			}
		}
	},
  	methods: {
		toggleExtend: function(treeData) {
			treeData.extend = !treeData.extend;

			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];
			if(jsonExtend.find(el => el.unique_id == treeData.unique_id)){
				jsonExtend.find(el => el.unique_id == treeData.unique_id).extend = treeData.extend
			}else{
				jsonExtend.push({ id: treeData.unique_id, extend: treeData.extend })
			}
			
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			this.parentOpen = !this.parentOpen;
			this.$forceUpdate();
		},
		showDeletedItems(item, type) {
			axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: item})
			.then(({data}) => {
				this.deletedItem = data.data[type][0]
			}).catch(error => {
				if(error.response) {
					if(error.response.status == 500) {
						alert(this.$t('error.500'))
					}
				}
			})
		},
		showModal(type, dataId = false, itemId = false){
			this.$root.$emit('open_modal', type, dataId, itemId);
		},
		showModalStep(type, stepType = false, data, json){
			this.$root.$emit('open_modal_step', type, stepType, JSON.stringify(data), JSON.stringify(json), this.processData)
		},
		openModalDelete(dataChild, type){
			var data = {
				treeData: JSON.stringify(dataChild),
				fullJson: JSON.stringify(this.fullJson),
				process: this.processData,
				type: type,
				from: 'process_chart'
			}

			this.$root.$emit('open_modal', 'delete', data);
		},
		centerOrg(){
			var calcWidth = ($('.scrollable-chart .center-box .parentLevel').first().children('.node').position().left * this.zoomPage) - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel').first().children('.node').width() / 2) * this.zoomPage) + 30

			$('.scrollable-chart').animate({
				scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
				scrollLeft: calcWidth
			}, 300);
		},
		viewProcess(processItem) {
			this.$router.push({ name: 'process-show', params: {slug: processItem.slug}, query: { parent: this.processData.slug } })	
		},
		changeOpacity(currentId, parentId){
			var organigramaDiv = document.querySelector(`.organigram-tbf`)
			var currentDiv = document.querySelector(`.organigram-tbf #box-id-${currentId} .step-organigrama`)
			var parentDiv = document.querySelector(`.organigram-tbf #box-id-${parentId} .step-organigrama`)

			organigramaDiv.classList.add('loop')
			currentDiv.classList.add('show-in-loop')
			parentDiv.classList.add('show-in-loop', 'start-loop')
		},
		changeOpacity2(currentId, parentId){
			var organigramaDiv = document.querySelector(`.organigram-tbf`)
			var currentDiv = document.querySelector(`.organigram-tbf #box-id-${currentId} .step-organigrama`)
			var parentDiv = document.querySelector(`.organigram-tbf #box-id-${parentId} .step-organigrama`)

			organigramaDiv.classList.remove('loop')
			currentDiv.classList.remove('show-in-loop')
			parentDiv.classList.remove('show-in-loop', 'start-loop')
		},
		scrollToParent(parentId){
			var organigramaDiv = document.querySelector(`.organigram-tbf`);
			var parentDiv = document.querySelector(`.organigram-tbf #box-id-${parentId} .step-organigrama`);
			
			parentDiv.scrollIntoView({
				behavior: 'smooth'
			});

			setTimeout(() => {
				organigramaDiv.classList.add('loop');
				parentDiv.classList.add('show-in-loop', 'start-loop');
	
				setTimeout(() => {
					organigramaDiv.classList.remove('loop')
					parentDiv.classList.remove('show-in-loop')
				}, 2000);
			}, 500)

		}
  	}
};
</script>

<style lang="scss">
	.title{
		&.deleted{
			text-decoration: line-through;
		}
	}
	.decision-popover {
		max-height: 30px;
	}
</style>
